<template>
  <div class="container-fluid no-scroll">
    <div class="row bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Shipping Info
      </div>
    </div>
  </div>

  <div class="container-fluid no-scroll mt-2">
    <div class="row px-0 small">
      <div class="col-12 bg-white py-2">
        <div class="order">
          <div class="order-header">
            <span>No.{{ order.order_id }}</span> <span>{{ order.created_time }}</span>
          </div>

          <div class="order-body" v-for="(stock,index) in order.stocks" v-bind:key="index">
            <img class="image" :src="stock.images[1]" @click="showImages(stock.images)">
            <div class="info d-flex flex-column justify-content-around ml-1 flex-grow-1">
              <div class="model fw-bold">
                <span class="badge bg-primary">{{ stock.grade }}</span> {{ stock.brand }} {{ stock.name }}
              </div>
              <div class="spec">
                <span class="attribute">{{ stock.attributes }} </span>
              </div>

              <div class="row mt-2 d-flex align-items-center justify-content-between">
                <div class="col-4 d-flex justify-content-between align-items-center">
                  <span class="price">{{ toThousands(stock.unit_price) }}</span>
                  <span style="margin-left: 0.5rem;font-size:0.5rem; color: #777;">x {{ stock.qty }}</span>
                </div>
                <div class="col-8 text-end">
                  {{ toThousands(stock.unit_price * stock.qty) }}
                </div>
              </div>
            </div>
          </div>

          <div class="order-sum">
            <div class="row">
              <div class="col-12 d-flex justify-content-end align-items-baseline">
                <span>Sum: &nbsp;</span> <span class="text-danger fw-bold"> {{
                  toThousands(order.total_net_amount)
                }} </span>
                <span class="text-danger" style="margin-left:0.2rem;font-size: 0.5rem;">AED</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid no-scroll mt-2">
    <div v-if="order.pickup === pickupCode.SELF_PICKUP" class="row bg-white px-0 small">
      <div class="col-12 fw-bold py-2">
        Self pickup info
      </div>
      <div class="col-12 py-2" style="border-top: 1px solid #f1f1f1">

      </div>
    </div>

    <div v-if="order.pickup === pickupCode.NORMAL_SHIPPING" class="row bg-white px-0 small">
      <div class="col-12 fw-bold py-2">
        Self pickup info
      </div>
      <div class="col-12 py-2" style="border-top: 1px solid #f1f1f1">

      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import photoSwipe from '@/plugins/photoswipe'
import { PickupCode } from '@/api/codes'
import { getShippingInfo } from '@/api/order'

export default {
  name: 'ShippingInfo',

  computed: {
    ...mapState('order', ['order'])
  },

  mounted () {
    this.loadShippingInfo()
  },

  data () {
    return {
      pickupCode: PickupCode,
      pickupStation: undefined,
      shippingProvider: undefined
    }
  },

  methods: {
    showImages (images) {
      const swipeItems = []
      for (const key in images) {
        swipeItems.push({
          src: images[key],
          w: 1024,
          h: 1024
        })
      }
      photoSwipe.preview(swipeItems)
    },

    loadShippingInfo () {
      getShippingInfo({
        order_id: this.order.order_id,
        pickup: this.order.pickup,
        pickup_station_id: this.order.pickup_station_id,
        shipping_provider_id: this.order.shipping_provider_id
      }).then(res => {
        console.log(res)
        this.pickupStation = res.data.pickupStation
        this.shippingProvider = res.data.shippingProvider
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">

.order {
  .order-header {
    padding: 0.2rem 0;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .order-body {
    padding: 0.5rem 0;
    border-top: 1px solid #f5f5f5;
    display: flex;
    align-items: center;

    .image {
      width: 5rem;
      height: 5rem;
      padding: 0.5rem;
      border-radius: 10%;
    }

    .info {
      padding-left: 1rem;

      .model {
        font-size: 0.8rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .spec {
        margin: 0.3rem 0;
        font-size: 0.6rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .price {

      }
    }
  }

  .order-sum {
    text-align: right;
  }

  .order-footer {
    border-top: 1px solid #f5f5f5;
    padding: 0.8rem 0 0.3rem 0;
  }
}

</style>
