<template>
  <div class="container-fluid no-scroll">
    <div class="row bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Charge your Balance
      </div>
    </div>

    <div class="row mt-1 bg-white">
      <div class="col-12 py-3 small fw-bold">
        Please wire to the REER official account below.
      </div>
    </div>

    <div class="row mt-1 bg-white px-1 py-2 small">
      <div class="col-4 name">
        Account Name
      </div>
      <div class="col-7 value" id="name">
        Reer Global Hong Kong Limted
      </div>
      <div class="col-1 clipboard">
        <fa icon="copy" @click="copyToClipboard('name')"></fa>
      </div>
    </div>

    <div class="row mt-1 bg-white px-1 py-2">
      <div class="col-4 name">
        Account Number
      </div>
      <div class="col-7 value" id="account">
        3223232323323
      </div>
      <div class="col-1 clipboard">
        <div class="col-1 clipboard">
          <fa icon="copy" @click="copyToClipboard('account')"></fa>
        </div>
      </div>
    </div>

    <div class="row mt-1 bg-white px-1 py-2">
      <div class="col-4 name">
        Bank Name
      </div>
      <div class="col-7 value" id="branch">
        Standard Charter Hong Kong
      </div>
      <div class="col-1 clipboard">
        <fa icon="copy" @click="copyToClipboard('branch')"></fa>
      </div>
    </div>

    <div class="row mt-1 bg-white px-1 py-2">
      <div class="col-4 name">
        Bank ID
      </div>
      <div class="col-7 value" id="bankId">

      </div>
      <div class="col-1 clipboard">
        <fa icon="copy" @click="copyToClipboard('bankId')"></fa>
      </div>
    </div>

    <div class="row mt-1 bg-white px-1 py-2">
      <div class="col-4 name">
        Swift Code
      </div>
      <div class="col-7 value" id="swift">

      </div>
      <div class="col-1 clipboard">
        <fa icon="copy" @click="copyToClipboard('swift')"></fa>
      </div>
    </div>

    <div class="row mt-1 bg-white px-1 pt-2 pb-1">
      <div class="col-4 name">
        Wire Note
      </div>
      <div class="col-7 value" id="note">
        {{ $store.state.auth.user.email }}
      </div>
      <div class="col-1 clipboard">
        <fa icon="copy" @click="copyToClipboard('note')"></fa>
      </div>
      <div class="col-12 mt-2" style="font-size:0.6rem;color:red;">
        *** Wire note must be attached to identify wire sender.
      </div>
    </div>

    <div class="row bg-white py-4 mt-1">
      <div class="col-12 text-center">
        <span class="small">
          Your account balance: <span class="text-danger fs-6">{{ toThousands(user.account_balance) }}</span>
          <span class="text-danger" style="font-size: 0.6rem;margin-left: 0.2rem;">AED</span></span>
      </div>
      <div class="col-12 mt-2">
        <button class="btn bg-primary w-100 text-white" @click="refreshUserInfo">Reload account balance after charge
        </button>
      </div>
    </div>

    <div class="row bg-white px-2 py-4 mt-1 small">
      <div class="col-12 text-center fw-bold">
        Charge notice
      </div>
      <div class="col-12 mt-3" style="font-size: 0.8rem;">
        * Ordinarily, your payment will arrive in 1~2 days.
      </div>
      <div class="col-12 mt-3" style="font-size: 0.8rem;">
        * As soon as payment arrived, your order will be secured.
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  name: 'Charge',

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },

  methods: {
    ...mapActions('auth', ['refreshUserInfo']),

    copyToClipboard (id) {
      const content = document.getElementById(id).innerText
      const input = document.createElement('textarea')
      document.body.appendChild(input)
      input.value = content // 修改文本框的内容
      input.select() // 选中文本
      document.execCommand('copy') // 执行浏览器复制命令
      document.body.removeChild(input)
      this.$toast.info('Copied to clipboard', { duration: 1000 })
    }
  }
}
</script>

<style scoped lang="scss">

.name {
  font-size: 0.8rem;
  color: #777;
}

.value {
  font-size: 0.8rem;
  color: #000;
}

.clipboard {
  color: #666;
  font-size: 0.8rem;
}

</style>
