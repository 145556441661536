<template>
  <div class="container-fluid no-scroll">
    <div class="row bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Select Shipping Provider
      </div>
    </div>
  </div>

  <div v-for="provider in providers" v-bind:key="provider.id">
    <div class="d-flex justify-content-between align-items-center bg-white my-2">
      <div class="select">
        <input type="radio" name="shipping" @click="selectProvider(provider)">
      </div>
      <div class="provider">
        <div class="name">
          {{ provider.formal_name }}
        </div>
        <div class="web my-1">
          <a :href="provider.website" target="_blank">{{ provider.website }}</a>
        </div>
        <div class="contact">
          Contact: {{ provider.contact_first_name }} {{ provider.contact_last_name }},
          {{ provider.phone_code }} {{ provider.phone }} <br/>
          <fa icon="whatsapp" type="fab" style="margin:0.3rem 0.5rem 0.3rem 0; position: relative; top: -2px;"></fa>
          {{ provider.whatsapp }}
        </div>
        <div class="pricing">
          <span>Shipping Lines: </span>
          <ul style="list-style-type:none; padding-left: 0.5rem; border-top: 1px solid #f1f1f1;" class="my-1 py-1">
            <li v-for="(line, index) in provider.lines" v-bind:key="line.id">
              <span class="badge bg-primary">{{ index + 1 }}</span>
              {{ line.origin }} - {{ line.dest }}, {{ line.method }}: {{ line.pricing }}
            </li>
          </ul>
        </div>
        <div class="desc two-lines-ellipsis">
          {{ provider.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ShippingProvider',

  computed: {
    ...mapState({
      providers: state => state.order.providers
    })
  },

  methods: {
    ...mapActions('order', ['setSelectedProviderId']),

    selectProvider (provider) {
      this.setSelectedProviderId(provider.id)
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">

.select {
  width: 4rem;
  margin: 0 auto;
  text-align: center;
}

.provider {
  background: white;
  padding: 1rem;
  font-size: 0.8rem;

  .name {
    font-size: 0.9rem;
    font-weight: bold;
  }

  .phone {

  }

  .pricing {

  }

}

</style>
