<template>
  <div class="container-fluid no-scroll">
    <div class="container px-0">
    <div class="row bg-white mt-1" style="height: 5rem;">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <span>Welcome, {{ user.phone }}</span>
<!--        <span style="font-size: 0.9rem; text-align: center;" @click="$router.push({name:'balanceSheet'})">-->
<!--          {{ toThousands(user.account_balance) }} <span style="font-size: 0.6rem;">AED</span>-->
<!--          &lt;!&ndash;          <br/>&ndash;&gt;-->
<!--          &lt;!&ndash;          <span style="font-size: 0.6rem; color: #777">Reload Balance</span><br/>&ndash;&gt;-->
<!--          &lt;!&ndash;          <fa icon="sync-alt"></fa>&ndash;&gt;-->
<!--        </span>-->
      </div>
    </div>
    </div>
  </div>

  <div class="container-fluid no-scroll">
    <div class="container px-0">
    <div class="row bg-white mt-1">
      <div class="col-12 py-3 text-center small">
        <span class="text-base fw-bold py-1">Orders</span>
      </div>
      <div class="col-12" style="border-top: 1px solid #f1f1f1">
      </div>
    </div>
    <div class="row bg-white py-4 small">
      <div class="col-3 px-0 text-center" style="border-right: 1px solid #f1f1f1;" @click="showOrderList('Processing')">
        Processing
      </div>
      <div class="col-3 px-0 text-center" style="border-right: 1px solid #f1f1f1;" @click="showOrderList('Finished')">
        Finished
      </div>
      <div class="col-3 px-0 text-center position-relative" style="border-right: 1px solid #f1f1f1;"
           @click="showOrderList('Cancel')">
        Cancel
        <!--        <span class="num position-absolute border-danger text-danger"-->
        <!--              style="width:1rem; height:1rem;line-height:1rem;right: 20%; top:-20%;font-size:8px;padding:2px;border-radius: 30%;">3</span>-->
      </div>
      <div class="col-3 px-0 text-center" @click="showOrderList('All')">
        <span class="small">All</span>
      </div>
    </div>
    </div>
  </div>

  <div class="container-fluid no-scroll">
    <div class="container px-0">
    <div class="row bg-white mt-1 py-3 small">
      <div class="col-10">
        Address Book
      </div>
      <div class="col-2 text-end" @click="$router.push({name:'addressList'})">
        <fa icon="angle-right"></fa>
      </div>
    </div>
<!--    <div class="row bg-white py-3 small" style="margin-top: 1px;">-->
<!--      <div class="col-10">-->
<!--        Balance Book-->
<!--      </div>-->
<!--      <div class="col-2 text-end" @click="$router.push({name:'balanceSheet'})">-->
<!--        <fa icon="angle-right"></fa>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row bg-white py-3 small" style="margin-top: 1px;">
      <div class="col-10">
        FAQ
      </div>
      <div class="col-2 text-end" @click="$router.push({name:'faq'})">
        <fa icon="angle-right"></fa>
      </div>
    </div>
    <div class="row bg-white py-3 small" style="margin-top: 1px;">
      <div class="col-10">
        About
      </div>
      <div class="col-2 text-end" @click="$router.push({name:'about'})">
        <fa icon="angle-right"></fa>
      </div>
    </div>
<!--    <div class="row bg-white py-3 small" style="margin-top: 1px;">-->
<!--      <div class="col-10">-->
<!--        Feedback-->
<!--      </div>-->
<!--      <div class="col-2 text-end" @click="$router.push({name:'feedback'})">-->
<!--        <fa icon="angle-right"></fa>-->
<!--      </div>-->
<!--    </div>-->
    </div>
  </div>

  <div class="container-fluid no-scroll">
    <div class="container">
    <div class="row mt-1 py-4 small">
      <div class="col-12 text-center">
        <span data-bs-toggle="modal" data-bs-target="#removeModal" style="cursor: pointer;">Logout</span>
      </div>
    </div>
    </div>
  </div>

  <div class="modal fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="removeModalLabel">Confirm</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure to logout?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn text-white" style="background: #777" data-bs-dismiss="modal" @click="logout">Logout</button>
          <button type="button" class="btn bg-trans text-white" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UserCenter',

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },

  methods: {
    ...mapActions('auth', ['logout']),

    showOrderList (filter) {
      this.$router.push({ name: 'orderList', query: { status: filter } })
    }
  }
}
</script>

<style scoped>

.modal-dialog {
  /*transform: translate(-50%, 0);*/
  /*left: 50%;*/
  margin: 0 auto;
}

</style>
