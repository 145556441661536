<template>
  <div class="container-fluid no-scroll">
    <div class="row bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Balance Book
      </div>
    </div>
  </div>
  <div class="container-fluid no-scroll mt-2">
    <div class="row bg-white py-2">
      <div class="col-12 py-2 small d-flex justify-content-between">
        <span>
          Balance: {{
            toThousands(user.account_balance)
          }} AED
        </span>
        <router-link to="/user/charge" class="fw-bold">Charge your balance</router-link>
      </div>
      <div class="col-12 py-2 d-flex align-items-center justify-content-between">
        <div class="input-group input-group-sm">
          <span class="input-group-text">From</span><input type="date" v-model="start" class="form-control">
          <span class="input-group-text px-3">To</span><input type="date" v-model="end" class="form-control">
        </div>
      </div>
      <div class="col-12 mt-2 py-2">
        <div class="input-group input-group-sm">
          <select class="form-control" v-model="kind">
            <option value=0>All transactions</option>
            <option value=1>Pay Only</option>
            <option value=2>Charge Only</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 bg-white py-3 mt-1 d-flex justify-content-between" v-for="book in books" v-bind:key="book.id">
        <div>
          <span class="badge bg-primary">{{ book.name }}</span>
          <span class="d-block mt-1" style="color: #999; font-size: 0.7rem;">{{ book.created_time }}</span>
          <span class="d-block mt-1" style="color: #999; font-size: 0.7rem;" v-if="book.flow_id">
              Transaction Info: {{ book.flow_id }}
            </span>
        </div>
        <div :class="{'text-danger': book.kind === 2}">
          {{ book.kind === 2 ? '+' : '-' }} {{ toThousands(book.amount) }} {{ book.currency }}
        </div>
      </div>

      <div v-if="hasMoreItems" class="col-12 text-center my-3 small hover">
        <div class="text-dark" @click="loadMore()">
          <fa icon="angle-double-down"></fa>
          <br>Load More
        </div>
      </div>
      <div v-else class="col-12 text-center my-3 small hover">
        No more items
      </div>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getUserBooks } from '@/api/user'

export default {
  name: 'BalanceSheet',

  computed: {
    ...mapState('auth', ['user'])
  },

  watch: {
    start () {
      this.loadBooks()
    },

    end () {
      this.loadBooks()
    },

    kind () {
      this.loadBooks()
    }

  },

  data () {
    return {
      books: [],
      start: undefined,
      end: undefined,

      kind: 0,

      filters: {},
      sorters: { created_time: 'desc' },

      itemsPerPage: 10,
      pager: {
        limit: 10,
        offset: 0
      },
      currentPage: 1,
      hasMoreItems: true
    }
  },

  created () {
    this.loadBooks()
  },

  methods: {
    loadBooks () {
      this.filters = { buyer_id: this.user.id }

      if (this.start) {
        this.filters = { start: this.start, ...this.filters }
      }

      if (this.end) {
        this.filters = { end: this.end, ...this.filters }
      }

      if (this.kind > 0) {
        this.filters = { kind: this.kind }
      }

      this.$loading.show()
      getUserBooks({
        filters: this.filters,
        sorters: this.sorters,
        pager: this.pager
      }).then(res => {
        if (res.code === 0) {
          this.books = res.data.books
          if (!res.data.books || res.data.books.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    loadMore () {
      this.$loading.show()
      this.pager.offset += this.itemsPerPage
      getUserBooks({
        filters: this.filters,
        pager: this.pager,
        sorters: this.sorters
      }).then(res => {
        if (res.code === 0) {
          if (!res.data.books || res.data.books.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }

          if (res.data.books && res.data.books.length > 0) {
            this.books = this.books.concat(res.data.books)
          }
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
