import request from '@/utils/request'

const userApi = {
  GetCountryList: '/b/user/countries',
  GetCityList: '/b/user/cities',
  GetCaptcha: '/b/get_captcha',
  VerifyCaptcha: '/b/verify_captcha'
}

export function getCountryList (parameter) {
  return request({
    url: userApi.GetCountryList,
    method: 'post',
    data: parameter
  })
}

export function getCityList (parameter) {
  return request({
    url: userApi.GetCityList,
    method: 'post',
    data: parameter
  })
}

export function getCaptcha () {
  return request({
    url: userApi.GetCaptcha,
    method: 'get'
  })
}

export function verifyCaptcha (params) {
  return request({
    url: userApi.VerifyCaptcha,
    method: 'post',
    data: params
  })
}
