<template>
  <div class="container-fluid">
    <div class="container px-0">
      <div class="info-panel my-5">
        <div class="row pt-5">
          <div class="col-12 fs-4 text-center fw-bold">
            Login with Phone
          </div>
        </div>
        <div class="row px-3 pt-3 pb-5">
          <Form v-slot="{validate, errors}" :validation-schema="loginSchema" @submit="handleSubmit">
            <div class="col-12 pt-4">
              <label class="form-label" for="phoneInput">Phone / Whatsapp</label>
              <div class="input-group">
                <select ref="countryCodeSelect" class="form-select w-50" style="width: 50%">
                  <option v-for="country in countries" v-bind:key="country.id"
                          :selected="country.phone_code === countryCode" :value="country.phone_code">
                    {{ country.name }} {{ country.phone_code }}
                  </option>
                </select>
                <Field id="phoneInput" v-model="phoneNumber" as="input" class="form-control" name="phone"
                       placeholder="Phone Number" style="width: 50%" type="text"/>
              </div>
              <p class="text-danger small">{{ errors.phone }}</p>
            </div>

            <div class="col-12 mt -4">
              <label class="form-label" for="password">Password</label>
              <div class="input-group">
                <Field id="password" v-model="password" as="input" class="form-control" name="password"
                       type="password"/>
              </div>
            </div>

            <div class="col-12 mt-4">
              <button class="btn btn-primary w-100 mt-4 py-2 fw-bold login-btn" type="submit" @click="validate">Login
              </button>
            </div>
            <div class="col-12 mt-4 text-center">
              Doesn't have an account?
              <router-link to="/user/register">Go to register</router-link>
            </div>
          </form>
        </div>
      </div>

      <!--      <div v-if="!captchaOk" class="col-12 mt-4">-->
      <!--        <label class="form-label" for="vCaptcha">Captcha</label>-->
      <!--        <div class="input-group">-->
      <!--                <span class="input-group-text" style="width: 100px;">-->
      <!--                  <img style="height:1.6rem;" :src="captchaImg" @click="refreshCaptcha">-->
      <!--                </span>-->
      <!--          <Field id="vCaptcha" as="input" v-model="captchaCode" class="form-control" name="captcha" type="text"/>-->
      <!--          <button :disabled="verifying" class="btn btn-dark send-btn"-->
      <!--                  style="width:4rem;" type="button" @click="verifyCaptchaCode">Verify-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>

import { Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { login } from '@/api/user'
import { LoginCode } from '../../api/codes'

import { getCountryList } from '../../api/reer'
import { computed } from 'vue'
import { useStore } from 'vuex'
import md5 from 'md5'

export default {
  name: 'Login',
  components: {
    Field,
    Form
  },

  created () {
    this.loadCountryList({
      load_city: false,
      captcha: true
    })
  },

  data () {
    const loginSchema = yup.object().shape({
      phone: yup.string().required('Phone number is required'),
      password: yup.string().required('Password is required').min(6)
    })

    return {
      loginSchema,
      phoneNumber: this.phone || '',
      countries: this.cacheCountries || [],
      loginFinish: false,
      password: ''
    }
  },

  setup () {
    const store = useStore()
    const loginSucceed = ({
      token,
      logged,
      user
    }) => store.dispatch('auth/loginSucceed', {
      token,
      logged,
      user
    })

    const saveCountriesInCache = ({ countries }) => store.dispatch('reer/cacheCountries', { countries })

    const cacheCountries = computed(() => store.state.reer.countries)

    const phone = computed(() => {
      const userPhone = store.state.auth.userId
      if (userPhone && userPhone.length > 0) {
        return userPhone.split(' ')[1]
      } else {
        return undefined
      }
    })

    const countryCode = computed(() => {
      const userPhone = store.state.auth.userId
      if (userPhone && userPhone.length > 0) {
        return userPhone.split(' ')[0]
      } else {
        return undefined
      }
    })

    return {
      loginSucceed,
      saveCountriesInCache,
      phone,
      countryCode,
      cacheCountries
    }
  },

  methods: {
    loadCountryList (params) {
      getCountryList(params).then(res => {
        if (res.code === 0) {
          this.countries = res.data.countries
          this.saveCountriesInCache({ countries: this.countries })
        }
      }).catch(err => {
        console.log(err)
      })
    },

    // refreshCaptcha () {
    //   getCaptcha().then(res => {
    //     if (res.code === 0) {
    //       this.captchaId = res.data.captchaId
    //       this.captchaImg = res.data.captchaImg
    //     }
    //   })
    // },
    //
    // verifyCaptchaCode () {
    //   if (!this.captchaCode || this.captchaCode.length !== 4) {
    //     this.$toast.show('Please input 4 letters captcha')
    //     return
    //   }
    //
    //   this.verifying = true
    //
    //   verifyCaptcha({
    //     captchaId: this.captchaId,
    //     captchaCode: this.captchaCode
    //   }).then(res => {
    //     if (res.code === 0) {
    //       this.captchaOk = true
    //     } else if (res.code === -1) {
    //       this.$toast.show('Captcha is wrong, please check again')
    //     } else {
    //       this.$toast.show('Unable to get captcha, please try again')
    //     }
    //     this.verifying = false
    //   }).catch(err => {
    //     this.$toast.show('Unable to get captcha, please try again')
    //     console.log(err)
    //     this.verifying = false
    //   })
    // },
    // sendSmsCode () {
    //   if (!this.captchaOk) {
    //     this.showCaptcha = true
    //     return
    //   }
    //
    //   const phoneCode = this.$refs.countryCodeSelect.value
    //   if (!phoneCode || !this.phoneNumber || this.phoneNumber.length === 0) {
    //     this.$toast.info('Please select country code and your phone number')
    //     return
    //   }
    //   if (this.sent === true) {
    //     this.$toast.info('Too frequently, please try again a few seconds later')
    //     return
    //   }
    //
    //   this.sent = true
    //
    //   const phone = phoneCode + ' ' + this.phoneNumber
    //   sendCode({ phone }).then(res => {
    //     if (res.code === 0) {
    //       this.$toast.info('Verification code has been sent to your phone.')
    //     } else if (res.code === 1) {
    //       this.$toast.info('System busy, please try again later')
    //     } else if (res.code === 5) {
    //       this.$toast.info('You are requesting too frequent, please take a break and try again')
    //     } else {
    //       this.$toast.info('Some error unknown happened, please retry')
    //     }
    //   }).catch(err => {
    //     console.log(err)
    //     this.$toast.error(err)
    //   })
    // },

    handleSubmit (values) {
      values.phone = this.$refs.countryCodeSelect.value + ' ' + this.phoneNumber
      values.password = md5(values.password)

      this.$loading.show('Login to ReerGlobal...')

      login(values).then(res => {
        this.$loading.hide()

        if (res.code === LoginCode.SUCCEED) {
          this.$loading.show('Login succeed, redirecting ...')
          this.loginSucceed({
            token: res.data.token,
            logged: true,
            user: res.data.user
          })
          setTimeout(() => {
            this.loginFinish = true
            this.$router.push({ path: this.$route.query.redirect || '/' })
            this.$loading.hide()
          }, 2000)
        } else if (res.code === LoginCode.USER_NON_EXIST) {
          this.$toast.error('User phone doesn\'t exist, please try another one')
        } else if (res.code === LoginCode.WRONG_PASSWORD) {
          this.$toast.error('Wrong password, please try again')
        } else {
          if (res.code === 6) {
            this.$toast.info('Verification code expires, please resend')
          } else if (res.code === 5 || res.code === 7) {
            this.$toast.info('Verification code is wrong, please try again')
          }
        }
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    }
  }
}
</script>`

<style lang="scss" scoped>

.form-label {
  color: #6d737c;
}

.info-panel {
  background: white;
  border-radius: 1.4rem;
  z-index: 999;
  max-width: 30rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.countdown {
  background: #aaa;
  color: black;
}

.login-btn {
  border: none;
  background: linear-gradient(270deg, #F1651A 0%, #DE350B 100%);
}

.link-text {
  color: #0b1423;
  text-decoration: underline;
  text-underline: #0b1423;
}

.login {
  max-height: 0;
  overflow: hidden;
  transition: 0.8s;

  &.active {
    max-height: 50rem;
  }
}

</style>
