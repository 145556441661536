<template>
  <div class="container-fluid">
    <div class="container px-0">
      <div class="info-panel my-5">
        <div class="row pt-5">
          <div class="col-12 fs-4 text-center fw-bold">
            Register with your phone
          </div>
        </div>
        <div class="row px-3 pt-3 pb-5">
          <Form v-slot="{validate, errors}" :validation-schema="registerSchema" @submit="handleSubmit">
            <div class="col-12 pt-4">
              <label class="form-label" for="phoneInput">Phone / Whatsapp</label>
              <div class="input-group">
                <select ref="countryCodeSelect" class="form-select w-50" style="width: 50%">
                  <option v-for="country in countries" v-bind:key="country.id"
                          :selected="country.phone_code === countryCode" :value="country.phone_code">
                    {{ country.name }} {{ country.phone_code }}
                  </option>
                </select>
                <Field id="phoneInput" v-model="phoneNumber" as="input" class="form-control" name="phone"
                       placeholder="Phone Number"
                       style="width: 50%" type="text"/>
              </div>
              <p class="text-danger small">{{ errors.phone }}</p>
            </div>

<!--            <div class="captcha" :class="{'active': showCaptcha}">-->
<!--              <div class="col-12 mt-4">-->
<!--                <label class="form-label" for="vCaptcha">1. Captcha</label>-->
<!--                <div class="input-group">-->
<!--                            <span class="input-group-text" style="width: 100px;">-->
<!--                              <img :src="captchaImg" style="height:1.6rem;" @click="refreshCaptcha">-->
<!--                            </span>-->
<!--                  <Field id="vCaptcha" v-model="captchaCode" as="input" placeholder="Input captcha" class="form-control" name="captcha"-->
<!--                         type="text"/>-->
<!--                  <button :disabled="verifying" class="btn btn-dark send-btn"-->
<!--                          style="width:4rem;" type="button" @click="verifyCaptchaCode">Verify-->
<!--                  </button>-->
<!--                </div>-->
<!--                <p class="text-danger small">{{ errors.captcha }}</p>-->
<!--              </div>-->
<!--            </div>-->

            <div class="col-12 mt-4">
                <div class="input-group">
                  <Field id="vCode" as="input" name="code" placeholder="SMS code" class="form-control" type="text" required/>
                  <button ref="sendCodeBtn" type="button" style="width: 7rem" :class="{countdown : sent}"  class="btn btn-dark send-btn" @click="sendSmsCode">Send SMS
                  </button>
                </div>
              <p class="text-danger small">{{ errors.code }}</p>
            </div>

            <div class="col-12 mt-4">
              <label class="form-label" for="password">Password</label>
              <div class="input-group">
                <Field id="password" v-model="password" as="input" class="form-control" name="password"
                       type="password"/>
              </div>
              <p class="text-danger small">{{ errors.password }}</p>
            </div>

            <div class="col-12 mt-3">
              <label class="form-label" for="password2">Password Again</label>
              <div class="input-group">
                <Field id="password2" v-model="password2" as="input" class="form-control" name="password2"
                       type="password"/>
              </div>
              <p class="text-danger small">{{ errors.password2 }}</p>
            </div>

            <div class="col-12 mt-4">
              <button class="btn btn-primary w-100 mt-4 py-2 fw-bold login-btn" type="submit" @click="validate">
                Register
              </button>
              <!--              <div class="mt-3 text-center small">-->
              <!--                <span class="fw-bold" style="color: #de350b">*</span>ReerGood serve dealers only, not individual-->
              <!--              </div>-->
              <!--              <div class="mt-2 text-center small">-->
              <!--                <span class="fw-bold" style="color: #de350b">*</span>New user login will be registered automatically-->
              <!--              </div>-->
            </div>
            <div class="col-12 mt-4 text-center">
              Already have an account?
              <router-link to="/user/login">Go to login</router-link>
            </div>
          </Form>
        </div>
      </div>

      <!-- Modal -->
<!--      <div id="captchaModal" aria-hidden="true" aria-labelledby="captchaModalLabel" class="modal fade" tabindex="-1">-->
<!--        <div class="modal-dialog modal-dialog-centered">-->
<!--          <Form v-slot="{validate, errors}" :validation-schema="verificationSchema" @submit="handleVerifySubmit">-->
<!--            <div class="modal-content">-->
<!--              <div class="modal-header">-->
<!--                <h5 id="captchaModalLabel" class="modal-title">Please verify your phone</h5>-->
<!--                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>-->
<!--              </div>-->
<!--              <div class="modal-body mb-4" style="padding-top: 0">-->
<!--                <div class="col-12 mt-4">-->
<!--                  <label class="form-label" for="vCaptcha">1. Captcha</label>-->
<!--                  <div class="input-group">-->
<!--                            <span class="input-group-text" style="width: 100px;">-->
<!--                              <img :src="captchaImg" style="height:1.6rem;" @click="refreshCaptcha">-->
<!--                            </span>-->
<!--                    <Field id="vCaptcha" v-model="captchaCode" as="input" placeholder="Input captcha" class="form-control" name="captcha"-->
<!--                           type="text"/>-->
<!--                    <button :disabled="verifying" class="btn btn-dark send-btn"-->
<!--                            style="width:4rem;" type="button" @click="verifyCaptchaCode">Verify-->
<!--                    </button>-->
<!--                  </div>-->
<!--                  <p class="text-danger small">{{ errors.captcha }}</p>-->
<!--                </div>-->
<!--                <div class="col-12 mt-4">-->
<!--                    <label class="form-label" for="vCode">2. Phone verification code</label>-->
<!--                    <div class="input-group">-->
<!--                      <Field id="vCode" as="input" class="form-control" name="code" type="text"/>-->
<!--                      <button ref="sendCodeBtn" :class="{countdown : sent}" :disabled="sent" class="btn btn-dark send-btn" type="button" @click="validate">Submit-->
<!--                      </button>-->
<!--                    </div>-->
<!--                    <p class="text-danger small">{{ errors.code }}</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Form>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

import { Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { registerUser, sendCode } from '@/api/user'
import { RegisterResultCode } from '@/api/codes'

import {
  // getCaptcha,
  getCountryList
  // verifyCaptcha
} from '../../api/reer'
import { computed } from 'vue'
import { useStore } from 'vuex'
import md5 from 'md5'

export default {
  name: 'Register',
  components: {
    Field,
    Form
  },

  watch: {
    sent (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.startCountingDown()
      }
    }
  },

  created () {
    this.loadCountryList({
      load_city: false,
      captcha: true
    })
  },

  data () {
    const registerSchema = yup.object().shape({
      phone: yup.string().required('Phone number is required').min(6),
      password: yup.string().required('Password is required').min(6),
      password2: yup.string().required('Password2 is required').min(6)
        .test('value', 'Password doesn\'t match', val => val === this.password),
      code: yup.string().required('SMS code is required').min(6)
        .test('len', 'SMS code is 6 digits', val => val && val.length === 6)
    })

    return {
      registerSchema,
      phoneNumber: '',
      sent: false,
      countries: this.cacheCountries || [],
      loginFinish: false,
      password: '',
      password2: '',
      countdown: 120, // seconds
      countryCode: undefined,

      verificationOk: false
    }
  },

  setup () {
    const store = useStore()
    const loginSucceed = ({
      token,
      logged,
      user
    }) => store.dispatch('auth/loginSucceed', {
      token,
      logged,
      user
    })

    const saveCountriesInCache = ({ countries }) => store.dispatch('reer/cacheCountries', { countries })

    const cacheCountries = computed(() => store.state.reer.countries)

    const phone = computed(() => {
      const userPhone = store.state.auth.userId
      if (userPhone && userPhone.length > 0) {
        return userPhone.split(' ')[1]
      } else {
        return undefined
      }
    })

    return {
      loginSucceed,
      saveCountriesInCache,
      phone,
      cacheCountries
    }
  },

  methods: {
    resetCountingDown () {
      this.countdown = 120
      this.$refs.sendCodeBtn.innerText = 'Send'
      this.sent = false
    },

    startCountingDown () {
      if (this.loginFinish) {
        return
      }

      if (this.sent && this.countdown > 1) {
        this.countdown -= 1
        this.$refs.sendCodeBtn.innerText = this.countdown.toString()
        setTimeout(this.startCountingDown, 1000)
      } else {
        this.resetCountingDown()
      }
    },

    loadCountryList (params) {
      getCountryList(params).then(res => {
        if (res.code === 0) {
          this.countries = res.data.countries
          if (this.countries && this.countries.length > 0) {
            this.saveCountriesInCache({ countries: this.countries })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },

    // refreshCaptcha () {
    //   getCaptcha().then(res => {
    //     if (res.code === 0) {
    //       this.captchaId = res.data.captchaId
    //       this.captchaImg = res.data.captchaImg
    //     }
    //   })
    // },
    //
    // verifyCaptchaCode () {
    //   if (!this.captchaCode || this.captchaCode.length !== 4) {
    //     this.$toast.show('Captcha must be 4 characters')
    //     return
    //   }
    //   verifyCaptcha({
    //     captchaId: this.captchaId,
    //     captchaCode: this.captchaCode
    //   }).then(res => {
    //     if (res.code === 0) {
    //       this.showCaptcha = false
    //       this.captchaOk = true
    //     } else if (res.code === -1) {
    //       this.$toast.show('Captcha is wrong, please check again')
    //     } else {
    //       this.$toast.show('Unable to get captcha, please try again')
    //     }
    //   }).catch(err => {
    //     this.$toast.show('Unable to get captcha, please try again')
    //     console.log(err)
    //   })
    // },

    sendSmsCode () {
      const phoneCode = this.$refs.countryCodeSelect.value
      if (!phoneCode || !this.phoneNumber || this.phoneNumber.length === 0) {
        this.$toast.info('Please provide country code and your phone number')
        return
      }
      if (this.sent === true) {
        this.$toast.info('Too frequently, please try again a few seconds later')
        return
      }

      this.sent = true

      const phone = phoneCode + ' ' + this.phoneNumber
      sendCode({ phone }).then(res => {
        if (res.code === 0) {
          this.$toast.info('Verification code has been sent to your phone.')
        } else if (res.code === 1) {
          this.$toast.error(`Phone number ${phone} has been used, please try another or login with this number`)
          this.resetCountingDown()
        } else if (res.code === -1) {
          this.$toast.error('Invalid argument, please check your phone number')
        } else if (res.code === 5) {
          this.$toast.error('You are requesting too frequent, please take a break and try again')
        } else {
          this.$toast.error('System busy, please retry later')
        }
      }).catch(err => {
        console.log(err)
        this.$toast.error(err)
      })
    },

    handleSubmit (values) {
      values.phone = this.$refs.countryCodeSelect.value + ' ' + this.phoneNumber
      values.password = md5(values.password)
      delete values.password2

      this.$loading.show('Registering new account...')
      registerUser(values).then(res => {
        if (res.code === RegisterResultCode.SUCCEED) {
          this.$loading.show('Registration succeed, redirecting to login ...')
          setTimeout(() => {
            this.$router.push({ name: 'login' })
            this.$loading.hide()
          }, 2000)
        } else {
          this.$loading.hide()
          if (res.code === RegisterResultCode.VERIFICATION_CODE_EXPIRE) {
            this.$toast.info('Verification code expires, please resend')
          } else if (res.code === RegisterResultCode.WRONG_VERIFICATION_CODE) {
            this.$toast.info('Verification code is wrong, please try again')
          } else if (res.code === RegisterResultCode.VERIFICATION_CODE_NON_EXIST) {
            this.$toast.info('Verification code not sent, please retry')
          }
        }
      }
      ).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    }
  }
}
</script>`

<style lang="scss" scoped>

.form-label {
  color: #6d737c;
}

.info-panel {
  background: white;
  border-radius: 1.4rem;
  z-index: 999;
  max-width: 30rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.countdown {
  background: #aaa;
  color: black;
}

.login-btn {
  border: none;
  background: linear-gradient(270deg, #F1651A 0%, #DE350B 100%);
}

.link-text {
  color: #0b1423;
  text-decoration: underline;
  text-underline: #0b1423;
}

.captcha {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;

  &.active {
    max-height: 50rem;
  }
}

</style>
