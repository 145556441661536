<template>
  <div class="container-fluid no-scroll">
    <div class="row bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Add or Update Address
      </div>
    </div>
  </div>
  <div class="container-fluid mt-2 no-scroll">
    <div class="row bg-white">
      <div class="col-12 pt-4 text-center px-4">
        <Form v-slot="{validate, errors}" :validation-schema="schema" @submit="handleSubmit">
          <div class="text-start">
            <label for="firstName" class="form-label col-form-label-sm">First Name</label>
            <Field name="firstName" v-model="address.consignee_first_name" as="input" type="text"
                   class="form-control form-control-sm" id="firstName"/>
            <p class="text-danger small">{{ errors.firstName }}</p>
          </div>

          <div class="text-start">
            <label for="lastName" class="form-label col-form-label-sm">Last Name</label>
            <Field name="lastName" v-model="address.consignee_last_name" as="input" type="text"
                   class="form-control form-control-sm" id="lastName"/>
            <p class="text-danger small">{{ errors.lastName }}</p>
          </div>

          <div class="mb-3 text-start">
            <label for="emailInput" class="form-label">Email address</label>
            <Field name="email" v-model="address.email" as="input" type="email" class="form-control form-control-sm"
                   id="emailInput"/>
            <p class="text-danger small">{{ errors.email }}</p>
          </div>

          <div class="text-start">
            <label for="phoneInput" class="form-label col-form-label-sm">Phone</label>

            <div class="input-group">
              <select v-model="selectedCountryPhoneCode" class="form-select form-select-sm">
                <option v-for="country in countries" :value="country.phone_code" v-bind:key="country.id">
                  {{ country.name }} {{ country.phone_code }}
                </option>
              </select>
              <Field name="phone" as="input" v-model="address.phone" type="number" class="form-control form-select-sm"
                     id="phoneInput"/>
            </div>
            <p class="text-danger small">{{ errors.phone }}</p>
          </div>

          <div class="text-start">
            <label for="address_1" class="form-label col-form-label-sm">Address Line 1</label>
            <Field name="address_1" v-model="address.address_1" placeholder="Room, Building, Postbox..." as="input"
                   type="text" class="form-control form-control-sm" id="address_1"/>
            <p class="text-danger small">{{ errors.address_1 }}</p>
          </div>

          <div class="text-start">
            <label for="address_2" class="form-label col-form-label-sm">Address Line 2</label>
            <Field name="address_2" v-model="address.address_2" placeholder="Region, District, County..." as="input"
                   type="text" class="form-control form-control-sm" id="address_2"/>
            <p class="text-danger small">{{ errors.address_2 }}</p>
          </div>

          <div class="text-start">
            <label for="country" class="form-label col-form-label-sm">Country</label>
            <div class="input-group">
              <select v-model="selectedCountryId" class="form-select form-select-sm" id="country">
                <option v-for="country in countries" :value="country.id" v-bind:key="country.id">{{ country.name }}
                </option>
              </select>
            </div>
            <p class="text-danger small">{{ errors.country }}</p>
          </div>

          <div class="text-start">
            <label for="city" class="form-label col-form-label-sm">City</label>
            <div class="input-group">
              <select v-model="selectedCityId" class="form-select form-select-sm" id="city">
                <option v-for="city in cities" :value="city.id" v-bind:key="city.id">{{ city.name }}</option>
              </select>
            </div>
            <p class="text-danger small">{{ errors.city }}</p>
          </div>

          <button type="submit" @click="validate" class="btn btn-primary w-100 mt-2 mb-5 fw-bold">Save</button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { getCityList, getCountryList } from '@/api/reer'
import { addAddress } from '@/api/user'
import { mapActions } from 'vuex'

export default {
  name: 'AddAddress',

  components: {
    Field,
    Form
  },

  created () {
    this.$nextTick(() => {
      if (!this.$route.params.address) {
        this.edit = false
        this.loadCountryList({ load_city: true })
      } else {
        this.edit = true
        this.address = JSON.parse(this.$route.params.address)
        this.loadCountryList({ load_city: false })
      }
    })
  },

  data () {
    const schema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required().email(),
      phone: yup.number().required(),
      address_1: yup.string().required(),
      address_2: yup.string().nullable()
    })

    return {
      selectedCountryPhoneCode: undefined,

      selectedCountryId: undefined,
      countries: [],

      selectedCityId: undefined,
      cities: [],

      address: {},

      edit: false,
      schema
    }
  },

  watch: {
    selectedCountryId (newId, oldId) {
      if (!oldId || oldId === newId) {
        return
      }

      this.loadCityList({ country_id: newId })
    }
  },

  methods: {

    ...mapActions('order', ['clearUserAddress']),

    handleSubmit (values) {
      values.buyer_id = this.$store.state.auth.user.id
      values.consignee_first_name = values.firstName
      values.consignee_last_name = values.lastName
      values.country_id = this.selectedCountryId
      values.phone_code = this.selectedCountryPhoneCode
      values.city_id = this.selectedCityId
      values.id = this.address ? this.address.id : undefined
      delete values.firstName
      delete values.lastName

      addAddress(values).then(res => {
        if (res.code === 0) {
          if (this.edit) {
            this.$toast.success('Successfully updated address')
          } else {
            this.$toast.success('Successfully added new address')
          }
          // 删掉所有地址，让系统重新从服务器请求地址
          this.clearUserAddress()

          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        }
      }
      ).catch(err => {
        console.log(err)
      })
    },

    loadCountryList (params) {
      getCountryList(params).then(res => {
        if (res.code === 0) {
          this.countries = res.data.countries
          if (this.countries && this.countries.length > 0) {
            if (this.edit) {
              this.selectedCountryId = this.address.country_id
              this.selectedCountryPhoneCode = this.address.phone_code
              this.loadCityList({
                country_id: this.selectedCountryId,
                edit: true
              })
            } else {
              this.selectedCountryId = this.countries[0].id
              this.selectedCountryPhoneCode = this.countries[0].phone_code
            }
          }

          if (res.data.cities) {
            this.cities = res.data.cities
            if (this.cities && this.cities.length > 0) {
              this.selectedCityId = this.cities[0].id
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },

    loadCityList (params) {
      getCityList(params).then(res => {
        if (res.code === 0) {
          this.cities = res.data.cities
          if (this.cities && this.cities.length > 0) {
            if (params.edit) {
              this.selectedCityId = this.address.city_id
            } else {
              this.selectedCityId = this.cities[0].id
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">

.form-label {
  margin-bottom: 0;
}

.col-form-label-sm {
  padding-top: 0;
  padding-bottom: 0;
}

</style>
