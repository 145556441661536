<template>
  <div class="container-fluid no-scroll">
    <div class="row header bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Order Payment
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 d-flex justify-content-between align-items-center pt-3 bg-white">
        <span>Order ID</span>
        <span style="font-size: 0.7rem;">{{ order.order_id }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center py-3 bg-white">
        <span>Order total amount</span>
        <span style="color:red; font-size: 1.2rem;">{{ toThousands(order.total_net_amount) }} <span
          style="font-size: 0.6rem;">AED</span></span>
      </div>
    </div>
    <div class="row mt-1 bg-white py-5">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <span>Amount to pay</span>
        <input type="number" v-model="amount" style="margin-left: 1rem;" class="flex-grow-1"
               placeholder="Pay 10% at minimum">
      </div>
      <div class="col-12 d-flex justify-content-end align-items-center my-2">
        <span class="balance">
          Your account balance:
          <span class="value">{{ toThousands(user.account_balance) }}</span>
          <span class="postfix">AED</span>
        </span>
        <fa style="color:#777; margin-left: 0.6rem; width: 1.2rem; height: 1.2rem;" icon="sync-alt"
            @click="refreshUserInfo"></fa>
      </div>
      <div class="col-12">
        <button class="btn w-100 my-2 fw-bold"
                :class="{'btn-secondary' : !enoughBalance, 'btn-primary': enoughBalance}"
                :disabled="!enoughBalance" @click="payOrder">Pay
        </button>
      </div>
    </div>

    <div class="row bg-white py-5 mt-1">
      <div class="col-12 text-center">
        Not enough balance?
      </div>
      <div class="col-12 mt-3">
        <button class="btn w-100 my-2 fw-bold btn-primary" @click="charge">
          Charge your balance
        </button>
      </div>
    </div>

    <div class="row bg-white px-2 py-5 mt-1 small">
      <div class="col-12 text-center fw-bold" style="color: red;">
        Important payment notice
      </div>
      <div class="col-12 mt-3 small">
        * A minimal 10% has to be paid to secure ordered items.
      </div>
      <div class="col-12 mt-3 small">
        * The rest of the payment must be paid before pickup.
      </div>
      <div class="col-12 mt-3 small">
        * You may pay full amount to save bank transfer fees.
      </div>
      <div class="col-12 mt-3 small">
        * If the final payment not completed in 10 natural days, your
        advance payment will not be refund.
      </div>

    </div>

  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import { pay } from '@/api/order'
import { PayResultCode } from '@/api/codes'

export default {
  name: 'Order',

  computed: {
    enoughBalance () {
      return this.user.account_balance >= this.order.total_net_amount * 0.1
    },
    ...mapState('order', ['order']),
    ...mapState('auth', ['user']),
    minimumAmount () {
      return Math.ceil(this.order.total_net_amount * 0.1)
    }
  },

  mounted () {
    if (this.order.paid_amount > 0) {
      this.$router.push({ name: 'finalPayment' })
    }
  },

  data () {
    return {
      amount: ''
    }
  },

  methods: {

    ...mapActions('auth', ['updateBalance', 'refreshUserInfo']),
    ...mapActions('order', ['updateOrderInfo', 'setOrderStatus']),

    charge () {
      this.$router.push({
        name: 'charge'
      })
    },

    payOrder () {
      if (this.amount && parseInt(this.amount) > 0) {
        if (this.amount < this.minimumAmount) {
          this.$toast.error(`A minimal 10% (${this.minimumAmount}) has to be paid.`)
        } else if (this.amount > this.user.account_balance) {
          this.$toast.error('Insufficient balance, please charge your balance first.')
        } else {
          this.$loading.show()
          pay({
            buyer_id: this.user.id,
            order_id: this.order.order_id,
            amount: this.amount
          }).then(res => {
            if (res.code === 0) {
              // 执行成功
              this.updateBalance(res.data.new_balance)
              this.updateOrderInfo(res.data.order)
              this.$toast.success('Payment made successfully.')

              if (res.data.order.paid) {
                setTimeout(() => {
                  // 如果支付已经完全OK，那么跳转到订单列表的'待发货'列表
                  this.setOrderStatus('Deliver')
                  this.$router.push({ name: 'orderList' })
                }, 1000)
              } else {
                setTimeout(() => {
                  this.$router.push({ name: 'finalPayment' })
                }, 1000)
              }
            } else if (res.code === PayResultCode.INSUFFICIENT_BALANCE) {
              this.$toast.error('Insufficient balance, please charge')
              this.refreshUserInfo() // 可能是余额显示错误，重新加载
            } else if (res.code === PayResultCode.ORDER_CANCEL) {
              this.$toast.error('Order has been canceled.')
            } else if (res.code === PayResultCode.ORDER_HAS_BEEN_PAID) {
              this.$toast.warning('Order has been paid.')
            }
            this.$loading.hide()
          }).catch(err => {
            this.$loading.hide()
            console.log(err)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

input::-webkit-input-placeholder {
  /* placeholder颜色 */
  color: #aab2bd;
  /* placeholder字体大小 */
  font-size: 0.85rem;
  padding-left: 0.5rem;
}

.balance {
  font-size: 0.8rem;
  color: #333;

  .value {
    font-size: 1rem;
    color: red;
  }

  .postfix {
    font-size: 0.6rem;
    margin-left: 0.3rem;
    color: red;
  }
}

</style>
