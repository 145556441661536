<template>
  <span>{{ day ? dayString + 'd ' + hourString + ':' + minuteString + ':' + secondString : hourString + ':' + minuteString + ':' + secondString }}</span>
</template>

<script>
export default {
  name: 'Countdown',
  data () {
    return {
      day: '',
      hour: '',
      minute: '',
      second: '',
      promiseTimer: ''
    }
  },
  props: {
    remainTime: { // 倒计时间总秒数
      default: ''
    }
  },
  mounted () {
    if (this.remainTime > 0) {
      this.day = Math.floor(this.remainTime / (24 * 3600))
      this.hour = Math.floor((this.remainTime / 3600) % 24)
      this.minute = Math.floor((this.remainTime / 60) % 60)
      this.second = Math.floor(this.remainTime % 60)
      this.countDown()
    } else {
      this.$emit('countDownStop', true)
    }
  },

  emits: ['countDownStop', 'countDownEnd'],

  methods: {
    countDown () {
      const self = this
      clearInterval(this.promiseTimer)
      this.promiseTimer = setInterval(function () {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0
            if (self.day === 0) {
              self.$emit('countDownEnd', true)
              clearInterval(self.promiseTimer)
            } else {
              self.day -= 1
              self.hour = 23
              self.minute = 59
              self.second = 59
            }
          } else {
            self.second -= 1
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1
            self.minute = 59
            self.second = 59
          } else {
            self.second -= 1
          }
        }
      }, 1000)
    },
    formatNum (num) {
      return num < 10 ? '0' + num : '' + num
    }
  },
  computed: {
    dayString () {
      return this.day
    },
    hourString () {
      return this.formatNum(this.hour)
    },
    minuteString () {
      return this.formatNum(this.minute)
    },
    secondString () {
      return this.formatNum(this.second)
    }
  }
}
</script>
