<template>
  <div class="container-fluid no-scroll">
    <div class="row bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Submit Order Result
      </div>
    </div>
  </div>

  <div class="container-fluid no-scroll">
    <div class="row bg-white">
      <div class="col-12 small pt-1 pb-2">
        Order has been created, please complete the payment in time to secure your orders.
      </div>
    </div>
  </div>

  <!-- 订单项列表 -->
  <div class="container-fluid no-scroll mt-2 all-rounded">
    <div class="row bg-white">
      <div class="col-12  py-2 d-flex justify-content-between align-items-center">
        <div class="order-id text-primary"><span class="badge bg-primary small">No.</span>{{ order.order_id }}</div>
        <div class="order-time">{{ order.created_time }}</div>
      </div>
    </div>
    <div class="row bg-white" style="margin-top: 1px;">
      <div class="col-12 d-flex align-items-center justify-content-between" v-for="(item, index) in order.stocks"
           v-bind:key="index"
           style="border-bottom: 1px solid #f9f9f9">
        <div class="col-10 simple-product">
          <div class="name">
            {{ item.name }}
          </div>
          <div class="spec">
            {{ item.specs }}
          </div>
          <div class="price">
            {{ toThousands(item.unit_price) }} {{ item.currency }}
          </div>
        </div>
        <div class="col-2 quantity" style="text-align: right;">
          x {{ item.qty }}
        </div>
      </div>
      <div class="col-12 d-flex align-items-baseline justify-content-between py-2">
        <span style="font-size: 0.8rem;"><span class="badge bg-dark">Expire</span> <Countdown
          :remain-time="secondsRemain(order.created_time, order.expire)"></Countdown></span>
        <span style="font-size: 0.8rem;">Total:
            <span class="fw-bold" style="color:red;">
              {{ toThousands(order.total_net_amount) }}
            </span>
            <span style="font-size: 0.6rem; margin-left: 0.1rem; color:red;"> AED</span>
          </span>
      </div>
    </div>
  </div>

  <div class="container-fluid no-scroll mt-2">
    <div class="row bg-white">
      <div class="col-12 py-2">
        <button class="btn bg-primary text-white w-100 fw-bold" @click="payOrder">Pay</button>
      </div>
    </div>
  </div>

  <div class="container-fluid no-scroll mt-2" v-if="order.ordersLeft && order.ordersLeft > 0">
    <div class="row bg-white py-5 mt-1">
      <div class="col-12 text-center small">
        You still have {{ order.ordersLeft }} pending {{ order.ordersLeft > 1 ? 'orders' : 'order' }} to submit.
      </div>
      <div class="col-12 mt-3 text-center">
        <button class="btn w-50 btn-sm my-2 btn-outline-secondary" @click="$router.go(-1)">
          Go back to submit
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid no-scroll">
    <div class="row bg-white px-2 py-5 mt-2 small">
      <div class="col-12 text-center fw-bold" style="color: red;">
        Important payment notice
      </div>
      <div class="col-12 mt-3 small">
        * The final payment must be paid before delivery or pickup.
      </div>
      <div class="col-12 mt-3 small">
        * You may pay full amount to avoid multiple bank transfer fees.
      </div>
      <div class="col-12 mt-3 small">
        * If the final payment not paid in 10 natural days, your
        advance payment will not be refund.
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import Countdown from '@/components/Countdown'

export default {
  name: 'SubmitOrderResult',
  components: { Countdown },
  data () {
    return {}
  },

  computed: {
    ...mapState('order', ['order'])
  },

  methods: {
    payOrder () {
      this.$router.push({ name: 'payment' })
    }
  }
}
</script>

<style scoped lang="scss">

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.simple-product {
  height: auto;
  padding: 1rem 0.5rem;

  .name {
    font-size: 0.9rem;
    //.ellipsis()
  }

  .spec {
    font-size: 0.7rem;
    color: #777;
    //.ellipsis()
  }

  .price {
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
}

.order-id {
  font-size: 0.8rem;
  line-height: 1rem;
}

.order-time {
  font-size: 0.7rem;
  line-height: 1rem;
  color: #aaa;
}

</style>
