<template>
  <div class="container-fluid no-scroll">
    <div class="row header bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Order Payment
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayResult'
}
</script>

<style scoped>

</style>
