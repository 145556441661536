<template>
  <div class="container-fluid no-scroll">
    <div class="row bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Address Book
      </div>
    </div>
  </div>
  <div v-for="address in addresses" v-bind:key="address.address.id">
    <div class="address-wrapper">
      <div class="address" @click="selectAddress(address)">
        <div class="contact">
          {{ address.address.consignee_first_name }} {{ address.address.consignee_last_name }}
        </div>
        <div class="addr">
          {{ address.address.address_1 }}, {{ address.address.address_2 }}
          <br>
          {{ address.city }}, {{ address.country }}
        </div>
        <div class="phone">
          {{ address.address.phone_code }} {{ address.address.phone }}
        </div>
      </div>
      <fa icon="edit" @click="editAddress(address.address)"></fa>
    </div>
    <div class="op-wrapper">
      <fa icon="trash" @click="addressToBeRemoved=address.address" data-bs-toggle="modal"
          data-bs-target="#removeModal"></fa>

      <span v-if="address.address.default" class="badge bg-primary py-2">Default</span>
      <span v-else class="badge bg-secondary py-2" @click="swapDefault(address.address)">Set as Default</span>
    </div>
  </div>

  <div class="w-100 text-center mt-3 py-3">
    <button class="btn btn-sm btn-outline-primary w-75" @click="addNewAddress">Add new address</button>
  </div>

  <div class="modal modal-sm fade" id="removeModal" tabindex="-1" aria-labelledby="removeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="removeModalLabel">Confirm</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure to remove the address?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="removeAddress">Remove</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { changeDefaultAddress, deleteAddress, getUserAddresses } from '@/api/user'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddressList',

  created () {
    // 优先从store中获取地址信息
    if (!this.addresses || this.addresses.length === 0) {
      this.loadAddress()
    }
  },

  data () {
    return {
      defaultAddressId: undefined,
      addressToBeRemoved: undefined
    }
  },

  computed: {
    ...mapState({
      buyer_id: state => state.auth.user.id,
      addresses: state => state.order.addresses
    })
  },

  methods: {
    ...mapActions('order', ['setSelectedAddressId', 'saveUserAddresses', 'setDefaultAddress']),

    loadAddress () {
      getUserAddresses({ buyer_id: this.buyer_id }).then(res => {
        if (res.code === 0) {
          if (res.data.addresses && res.data.addresses.length > 0) {
            this.saveUserAddresses(res.data.addresses)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },

    removeAddress () {
      const removedAddress = this.addressToBeRemoved
      deleteAddress({ id: removedAddress.id }).then(res => {
        if (res.code === 0) {
          // eslint-disable-next-line eqeqeq
          const addressList = this.addresses.filter(item => item.address.id != removedAddress.id)
          let defaultAddressId
          // 删除之后，没有地址了
          // eslint-disable-next-line eqeqeq
          if (addressList.length == 1) { // 删除之后，只剩一个地址了
            if (!addressList[0].address.default) {
              // 如果该地址不是default，强行设为default
              defaultAddressId = addressList[0].address.id
              changeDefaultAddress({ new: defaultAddressId }).then(res => {
                if (res.code === 0) {
                  console.log(defaultAddressId)
                }
              })
            }
          } else if (addressList.length > 1) { // 删除之后，还有多个地址
            if (removedAddress.default) { // 假如删除的地址是default，则剩下的把第一个设为default
              defaultAddressId = addressList[0].address.id
              changeDefaultAddress({ new: defaultAddressId }).then(res => {
                if (res.code === 0) {
                  console.log(defaultAddressId)
                }
              })
            }
          }

          this.saveUserAddresses(addressList)

          this.setDefaultAddress(defaultAddressId)

          this.addressToBeRemoved = undefined
        }
      }).catch(err => {
        console.log(err)
        this.$toast.error('Some orders are using this address')
      })
    },

    selectAddress (addr) {
      if (this.$route.params.goBack) {
        this.setSelectedAddressId(addr.address.id)
      }

      this.$router.go(-1)
    },

    editAddress (addr) {
      this.$router.push({
        name: 'addAddress',
        params: { address: JSON.stringify(addr) }
      })
    },

    swapDefault (addr) {
      let old
      let item
      // eslint-disable-next-line camelcase
      const addr_list = this.addresses.filter(item => item.address.default)
      // eslint-disable-next-line camelcase
      if (addr_list && addr_list.length > 0) {
        item = addr_list[0]
        old = item.address.id
      }

      const params = {
        old: old,
        new: addr.id
      }

      changeDefaultAddress(params).then(res => {
        if (res.code === 0) {
          this.setDefaultAddress(addr.id)
          this.$toast.success('Successfully set default address')
        }
      })
    },

    addNewAddress () {
      this.$router.push({ name: 'addAddress' })
    }
  }
}
</script>

<style scoped lang="scss">

.address-wrapper {
  background: white;
  margin-top: 0.5rem;
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .address {

    .contact {
      font-size: 0.9rem;
      font-weight: bold;
    }

    .addr {
      font-size: 0.8rem;
      color: #555;
    }

    .phone {
      margin-top: 0.2rem;
      font-size: 0.8rem;
    }
  }
}

.op-wrapper {
  background: white;
  margin-top: 1px;
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .default {
    font-size: 0.8rem;
    padding: 0.1rem 0.3rem;
    margin: 0;
  }
}

</style>
