<template>
  <div class="container-fluid no-scroll">
    <div class="row header bg-white" style="height: 4rem;">
      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">
        Final Payment
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-12 d-flex justify-content-between align-items-center py-3 bg-white">
        <span>Order total amount</span>
        <span>{{ toThousands(order.total_net_amount) }} <span style="font-size: 0.6rem;">AED</span></span>
      </div>
      <div class="col-12 d-flex justify-content-between align-items-center py-2 bg-white small">
        <span>Paid amount</span>
        <span>{{ toThousands(order.paid_amount) }} <span style="font-size: 0.6rem;">AED</span></span>
      </div>
      <!--      <div class="col-12 d-flex justify-content-between align-items-center py-3 bg-white" style="border-top:1px solid #f1f1f1;">-->
      <!--        <span>Final amount</span>-->
      <!--        <span class="text-danger" style="font-size: 1.2rem;">{{ toThousands(order.total_net_amount - order.paid_amount) }} <span style="font-size: 0.6rem;">AED</span></span>-->
      <!--      </div>-->
    </div>
    <div class="row bg-white py-3">
      <div class="input-group">
        <span class="input-group-text">Amount to Pay</span>
        <input type="number" v-model="amount" readonly class="form-control flex-grow-1 text-danger text-end"
               style="background: white">
      </div>
      <div class="col-12 d-flex justify-content-end my-2">
        <span class="balance">Your account balance: <span class="value text-danger">{{
            toThousands(user.account_balance)
          }}</span><span class="postfix text-danger">AED</span></span>
      </div>
      <div class="col-12">
        <button class="btn w-100 my-2 fw-bold"
                :class="{'btn-secondary' : !enoughBalance, 'btn-primary': enoughBalance}"
                :disabled="!enoughBalance" @click="payOrder">Pay
        </button>
      </div>
    </div>

    <div class="row bg-white py-5 mt-1">
      <div class="col-12 text-center">
        Insufficient balance?
      </div>
      <div class="col-12 mt-3">
        <button class="btn w-100 my-2 fw-bold btn-primary" @click="charge">
          Charge your balance
        </button>
      </div>
      <div class="col-12 text-center">

      </div>
    </div>

    <div class="row bg-white px-2 py-5 mt-1 small">
      <div class="col-12 text-center fw-bold" style="color: red;">
        Important payment notice
      </div>
      <div class="col-12 mt-3 small">
        * A minimal 10% has to be paid to secure ordered items.
      </div>
      <div class="col-12 mt-3 small">
        * The rest of the payment must be paid before pickup.
      </div>
      <div class="col-12 mt-3 small">
        * You may pay full amount to save bank transfer fees.
      </div>
      <div class="col-12 mt-3 small">
        * If the final payment not completed in 10 natural days, your
        advance payment will not be refund.
      </div>

    </div>

  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import { pay } from '@/api/order'

export default {
  name: 'Order',

  computed: {
    enoughBalance () {
      return this.user.account_balance >= this.amount
    },

    ...mapState('order', ['order']),
    ...mapState('auth', ['user'])
  },

  mounted () {
    this.$nextTick(() => {
      this.amount = this.order.total_net_amount - this.order.paid_amount
    })
  },

  data () {
    return {
      amount: ''
    }
  },

  methods: {

    ...mapActions('auth', ['updateBalance']),
    ...mapActions('order', ['setOrderStatus', 'updateOrderInfo']),

    charge () {
      this.$router.push({
        name: 'charge'
      })
    },

    payOrder () {
      if (this.amount && parseInt(this.amount) > 0) {
        if (this.amount < (this.order.total_net_amount - this.order.paid_amount)) {
          this.$toast.error(`(${this.order.total_net_amount - this.order.paid_amount}) has to be paid.`)
        } else {
          pay({
            buyer_id: this.user.id,
            order_id: this.order.order_id,
            amount: this.amount
          }).then(res => {
            if (res.code === 0) {
              // 执行成功
              this.updateBalance(res.data.new_balance)
              this.updateOrderInfo(res.data.order)
              this.$toast.success('Payment made successfully.')

              if (res.data.order.paid) {
                setTimeout(() => {
                  // 如果支付已经完全OK，那么跳转到订单列表的'待发货'列表
                  this.setOrderStatus('Deliver')
                  this.$router.push({ name: 'orderList' })
                }, 1000)
              } else {
                setTimeout(() => {
                  this.$router.push({ name: 'finalPayment' })
                }, 1000)
              }
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

input::-webkit-input-placeholder {
  /* placeholder颜色 */
  color: #aab2bd;
  /* placeholder字体大小 */
  font-size: 0.85rem;
  padding-left: 0.5rem;
}

.balance {
  font-size: 0.8rem;
  color: #333;

  .value {
    font-size: 1rem;
  }

  .postfix {
    font-size: 0.6rem;
    margin-left: 0.3rem;
  }
}

</style>
