<template>
  <img class="header-image" src="/user/register.png">
  <div class="container-fluid no-scroll py-5 bg-white">
    <div class="row pt-4">
      <div class="col-12 fs-4 text-center fw-bold">
        Activate your account
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-4 pt-4">
        An email with verification code has been sent to <span style="color:red; font-weight: bold">{{ email }}</span>,
        please input the code below.
      </div>
      <div class="col-12 py-2 text-center px-4">
        <div class="input-group">
          <input type="text" v-model="code" class="form-control" placeholder="Verification Code">
          <button v-if="resend" class="btn btn-primary" @click="resendVerificationCode">Resend</button>
          <button v-else type="button" class="btn btn-primary" @click="verifyEmailCode">Verify</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { sendCode, verifyUser } from '@/api/user'
import { RegisterResultCode } from '@/api/codes'

export default {
  name: 'VerifyRegister',

  data () {
    return {
      code: '',
      resend: false,
      email: this.$route.params.email
    }
  },

  methods: {
    verifyEmailCode () {
      if (!this.email || this.email.length === 0 || !this.code || this.code.length === 0) {
        console.log(this.email)
        return
      }

      verifyUser({
        email: this.email,
        code: this.code
      }).then(res => {
        if (res.code === 0) {
          this.$toast.success('Account activated, redirecting...')
          setTimeout(() => {
            this.$router.push({ name: 'login' })
          }, 1000)
        } else if (res.code === RegisterResultCode.VERIFICATION_CODE_EXPIRE) {
          this.$toast.error('Verification code has expired, please try to get a new one')
          this.resend = true
          this.code = ''
        }
      }).catch(err => {
        console.log(err)
      })
    },

    resendVerificationCode () {
      this.$loading.show('Resending verification code...')
      sendCode({ email: this.email }).then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.$toast.success('Verification code has been sent.')
          this.resend = false
        } else if (res.code === RegisterResultCode.ALREADY_ACTIVATED) {
          this.resend = false
          this.$toast.info('Your account has been activated, please login')
          setTimeout(() => {
            this.$router.push({ name: 'login' })
          }, 1000)
        }
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
